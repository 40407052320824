import ProviderLogRecord from '@components/SubmissionTabs/ProviderLogRecord';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { memo, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProviderLogRecordForm from '@components/SubmissionTabs/ProviderLogRecordForm';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import routes from '@constants/routes';
import LawsuitService from '@api/LawsuitService';
import useApi from '@hooks/useApi';
import ReportHeader from '@components/SubmissionTabs/ReportHeader';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';

function LawsuitProviderTab() {
  const [searchParams] = useSearchParams();
  const isExpert = useSelector(UserSelectors.isExpert);
  const logRecordRef = useRef<{ refresh(): void }>(null);

  const { data, isLoading, fetch } = useApi({
    method: LawsuitService.getById,
    initialData: {} as any,
  });

  useEffect(() => {
    fetch(Number(searchParams.get('id')));
  }, []);

  const handleSave = () => {
    logRecordRef.current?.refresh();
  }

  return (
    <>
      <SubmissionTabs active="provider" prefix={routes.lawsuitForm} />
      <ReportHeader
        name="Lawsuit Notification"
        createdAt={data.createdAt}
        reportNumber={data.reportNumber}
        riskPoints={10}
        loading={isLoading}
      />
      {!isExpert && <ProviderLogRecordForm reportType={ReportTypeEnum.Lawsuit} onSave={handleSave} />}
      <ProviderLogRecord reportType={ReportTypeEnum.Lawsuit} ref={logRecordRef} />
    </>
  );
}

export default memo(LawsuitProviderTab);
