import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  id: number;
  value: React.ReactNode;
  href: string;
}

function IdLink(props: Props) {
  const {id, value, href} = props;

  const link = `${href}/?id=${id}`;

  return (
    <RouterLink to={link} style={{textDecoration: 'none', color: '#1976d2'}}>
      {value}
    </RouterLink>
  );
}

export default memo(IdLink);
