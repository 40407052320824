import React from 'react';
import { useSelector } from 'react-redux';
import DashboardTotal from './DashboardTotal/DashboardTotal';
import DashboardFacility from './DashboardFacility/DashboardFacility';
import { SettingsSelectors } from '@store/slices/settings';
import { UserSelectors } from '@store/slices/user';
import DashboardExpert from './DashboardExpert/DashboardExpert';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

function DashboardPage() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const user = useSelector(UserSelectors.user);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);

  if (user.expertRole && dashboardMode === DashboardMode.Expert) {
    return <DashboardExpert />;
  }

  if (facility) {
    return <DashboardFacility />;
  }

  return <DashboardTotal />;
}

export default DashboardPage;
