import React from 'react';
import { useSelector } from 'react-redux';
import TotalMedicalReports from './TotalMedicalReports';
import FacilityMedicalReports from './FacilityMedicalReports';
import { SettingsSelectors } from '@store/slices/settings';
import { UserSelectors } from '@store/slices/user';
import MedicalReportsGrid from './components/MedicalReportsGrid';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

function MedicalRRRecords() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const user = useSelector(UserSelectors.user);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);
  if (user.expertRole && dashboardMode === DashboardMode.Expert) {
    return <MedicalReportsGrid medicalsReportGridKey={'facility-medicals'} gridFilterItem={undefined}/>
  }
  return facility ? <FacilityMedicalReports/> : <TotalMedicalReports/>;
}

export default MedicalRRRecords;
