import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { UseFormReturn } from 'react-hook-form';
import { ReportStatus } from '@declarations/common/enums/ReportStatus';
import { Incident } from '@declarations/models/reports/Incident';
import IncidentService from '@api/IncidentService';
import { UserType } from '@declarations/common/enums/UserType';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import CRUDForm from '../../components/CRUDForm';
import routes from '../../constants/routes';
import generateFields from './fields';
import { FormMode } from '../../hooks/useFormEdit';
import incidentFormValidationSchema from './utils/validationSchemas/incidentForm.validation-schema';
import isHighRisk from '../../utils/isHighRisk';
import ReportService from '@api/ReportService';
import { ReportTypeEnum } from '@declarations/common/reportType';
import LinkedReportsModal, { LinkedReportsModalProps } from '../../components/modals/LinkedReportsModal';
import MedicalRRService from '@api/MedicalRRService';
import LawsuitService from '@api/LawsuitService';
import useFacilityOptions from '@hooks/useFacilityOptions';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import HeaderBar from '@components/SubmissionTabs/HederBar';
import UserLogRecord from '@components/SubmissionTabs/UserLogRecord';
import RiskLevel from '@components/SubmissionTabs/RiskLevel';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

function IncidentForm() {
  const userType = useSelector(UserSelectors.type);
  const user = useSelector(UserSelectors.user);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);
  const navigate = useNavigate();
  const { facilities, selectedFacilityId } =  useFacilityOptions();
  const [dialogState, setDialogState] = useState<LinkedReportsModalProps>({open: false});

  const fields = useMemo(
    () => generateFields({ facilities, userType }),
    [facilities],
  );

  const defaultValues = useMemo(() => ({facility: selectedFacilityId}), [selectedFacilityId]);

  const onSubmit = async (
    data: Incident | FormData,
    form: UseFormReturn<Incident>,
  ) => {
    if (data instanceof FormData && !data.get('linkedReports[medicalReport][id]')) {
      const relations = (await MedicalRRService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.incident?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'MRRs',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    if (data instanceof FormData && !data.get('linkedReports[lawsuit][id]')) {
      const relations = (await LawsuitService.getRelations(data.get('facility') as string, data.get('residentMedicalRecordNumber') as string)).data;
      const availableRelations = relations?.filter(r => r.linkedReports?.incident?.id !== Number(data.get('id')));
      if (availableRelations?.length) {
        const dialog = new Promise((resolve) => {
          setDialogState({
            open: true,
            reportType: 'Lawsuits',
            onResult: resolve
          });
        });
        const result = await dialog;
        setDialogState({open: false});
        if (!result) { return; }
      }
    }

    const { data: responseData } = await IncidentService.upsert(data);

    if (responseData.id && data instanceof FormData) {
      await ReportService.setFacilityReportsRelation({
        sourceReport: ReportTypeEnum.Incident,
        incidentId: responseData.id,
        lawsuitId: Number(data.get('linkedReports[lawsuit][id]')) || null,
        mrrId: Number(data.get('linkedReports[medicalReport][id]')) || null,
      });
    }

    if (data instanceof FormData) {
      const oldRiskPoints = Number(data.get('riskPoints'));
      const newRiskPoints = responseData.riskPoints;

      if (!isHighRisk(oldRiskPoints) && isHighRisk(newRiskPoints)) {
        navigate(`${routes.incidentReportForm}/?id=${responseData.id}&mode=${FormMode.Edit}`);
        toast.warn('The situation has a HIGH-risk level. Please provide additional info.');

        form.setValue('riskPoints', newRiskPoints);
      } else {
        navigate(routes.incidents);

        if (responseData.calculatedStatus === ReportStatus.Completed) {
          toast.success((
            <>
              Incident Report has been saved.
              <br />
              The report is now completed.
            </>
          ));
        } else {
          toast.warn((
            <>
              Incident Report has been saved.
              <br />
              Please provide all information to complete the report.
            </>
          ));
        }
      }
    }
  };

  const editDisabled = (form: UseFormReturn<Incident>) =>
    userType !== UserType.SuperAdmin &&
    moment(form.watch('createdAt')).add(14, 'days').isBefore(moment());

  return (
    <>
      <SubmissionTabs active="" prefix={routes.incidentReportForm} />
      <HeaderBar title="Facility User" description="Complete the following" />
      <CRUDForm
        useFormData
        defaultValues={defaultValues}
        validationSchema={incidentFormValidationSchema}
        fetchEntityMethod={IncidentService.getById}
        renderTitle={(form, mode) => (
          mode === FormMode.Create
            ? <>New Incident Report</>
            : (
              <>
                Incident:
                {' '}
                {form.watch('reportNumber')}
                {' '}
                (
                {moment(form.watch('createdAt')).format('MM/DD/YYYY')}
                )
                {' '}
                <RiskLevel risk={form.watch('riskPoints')} />
              </>
            )
        )}
        onSubmit={onSubmit}
        fields={fields}
        backRoute={routes.incidents}
        editDisabled={editDisabled}
      />
      <UserLogRecord reportType={ReportTypeEnum.Incident} userType={userType} />
      <LinkedReportsModal {...dialogState} />
    </>
  );
}

export default IncidentForm;
