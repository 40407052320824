import { FetchListParams } from '@declarations/common/fetchListParams';
import { ListWithPagination } from '@declarations/common/pagination';
import { Incident } from '@declarations/models/reports/Incident';
import getFacilityIncidentsByTypeAndStatus from './getFacilityIncidentsByTypeAndStatus';
import getFacilityTotals from './getFacilityTotals';
import getCountsByHighRiskTypes from './getCountsByHighRiskTypes';
import getStatusByMonth from './getStatusByMonth';
import initInstance from '../axios';
import getIncidentsByFacilityAndType from '@api/IncidentService/getIncidentsByFacilityAndType';
import getFacilitiesIncidentsByStatus from '@api/IncidentService/getFacilitiesIncidentsByStatus';
import getTotalsByFacilities from '@api/IncidentService/getTotalsByFacilities';
import getFacilitiesIncidentsByType from '@api/IncidentService/getFacilitiesIncidentsByType';
import getRelations from './getRelations';
import getFacilitiesIncidentsByRiskLevel from '@api/IncidentService/getFacilitiesIncidentsByRiskLevel';
import setExpertUsers from '@utils/setExpertUsers';
import setUploadedFiles from '@utils/setUploadedFiles';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

const api = initInstance('/report/incident');

const paths = {
  upsert: '/',
  getById: (id: number) => `/${id}`,
  list: '/list',
  listCSV: '/list/csv',
  patch: (id: number) => `/${id}`,
  remove: '/remove',
};

const IncidentService = {
  upsert: async (lawsuit: Incident | FormData) => api.post<Incident>(paths.upsert, lawsuit),
  getById: async (id: number) => api.get(paths.getById(id)).then(setUploadedFiles),
  list: async (params: FetchListParams) =>
    api.post<ListWithPagination<Incident>>(paths.list, params).then(res => setExpertUsers(res)),
  listWithRole: (role: DashboardMode) => async (params: FetchListParams) =>
    api
      .post<ListWithPagination<Incident>>(paths.list, params, { params: { role_type: role } })
      .then(res => setExpertUsers(res)),
  patch: async (id: number, incident: Partial<Incident>) => api.patch(paths.patch(id), incident),
  remove: async (ids: number[]) => api.post(paths.remove, ids),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params),
  exportCSVWithRole: (role: DashboardMode) => async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params, { params: { role_type: role } }),
  getFacilityIncidentsByTypeAndStatus: getFacilityIncidentsByTypeAndStatus(api),
  getFacilityTotals: getFacilityTotals(api),
  //Used In Dashboard 1'st chart (admins)
  getFacilitiesIncidentsByRiskLevel: getFacilitiesIncidentsByRiskLevel(api),
  //Used in Dashboard (admins)
  getTotalsByFacilities: getTotalsByFacilities(api),
  getCountsByHighRiskTypes: getCountsByHighRiskTypes(api),
  getStatusByMonth: getStatusByMonth(api),
  //Used in Incidents 1'st chart (admins)
  getFacilitiesIncidentsByType: getFacilitiesIncidentsByType(api),
  getRelations: getRelations(api),
  //Used in Incidents 2'nd chart (admins)
  getIncidentsByFacilityAndType: getIncidentsByFacilityAndType(api),
  //Used in Incidents 3'd, 4'th charts (admins)
  getFacilitiesIncidentsByStatus: getFacilitiesIncidentsByStatus(api),
};

export default IncidentService;
