import { ListWithPagination } from '@declarations/common/pagination';
import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';
import { FetchListParams } from '@declarations/common/fetchListParams';
import getMRRRiskLevelByRequester from '@api/MedicalRRService/getMRRRiskLevelByRequester';
import getMRRStatusByRiskLevel from '@api/MedicalRRService/getMRRStatusByRiskLevel';
import initInstance from '../axios';
import getFacilityTotals from './getFacilityTotals';
import getCountsByHighRiskRequesters from './getCountsByHighRiskRequesters';
import getMRRRiskLevelByMonth from './getRiskLevelByMonth';
import getRiskLevelCountsByFacility from '@api/MedicalRRService/getRiskLevelCountsByFacility';
import getTotalsByFacilities from '@api/MedicalRRService/getTotalsByFacilities';
import getCountsByFacilities from '@api/MedicalRRService/getCountsByFacilities';
import getFacilitiesMedicalsByStatus from '@api/MedicalRRService/getMMRSByFacilityAndStatus';
import getFacilitiesCountsByRequester from '@api/MedicalRRService/getFacilitiesCountsByRequester';
import getRelations from './getRelations';
import setExpertUsers from '@utils/setExpertUsers';
import setUploadedFiles from '@utils/setUploadedFiles';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

const api = initInstance('/report/medical');

const paths = {
  upsert: '/',
  getById: (id: number) => `/${id}`,
  list: '/list',
  listCSV: '/list/csv',
  patch: (id: number) => `/${id}`,
  remove: '/remove',
};

const MedicalRRService = {
  upsert: async (medical: MedicalRecordRequest | FormData) =>
    api.post<MedicalRecordRequest>(paths.upsert, medical),
  getById: async (id: number) => api.get(paths.getById(id)).then(setUploadedFiles),
  list: async (params: FetchListParams) =>
    api
      .post<ListWithPagination<MedicalRecordRequest>>(paths.list, params)
      .then(res => setExpertUsers(res)),
  listWithRole: (role: DashboardMode) => async (params: FetchListParams) =>
    api
      .post<ListWithPagination<MedicalRecordRequest>>(paths.list, params, { params: { role_type: role } })
      .then(res => setExpertUsers(res)),
  patch: async (id: number, lawsuit: Partial<MedicalRecordRequest>) =>
    api.patch(paths.patch(id), lawsuit),
  remove: async (ids: number[]) => api.post(paths.remove, ids),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params),
  exportCSVWithRole: (role: DashboardMode) => async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params, { params: { role_type: role } }),
  getRiskLevelByRequester: getMRRRiskLevelByRequester(api),
  getFacilityTotals: getFacilityTotals(api),
  //Used for chips (admins)
  getTotalsByFacilities: getTotalsByFacilities(api),
  getStatusByRiskLevel: getMRRStatusByRiskLevel(api),
  getCountsByHighRiskRequesters: getCountsByHighRiskRequesters(api),
  getRiskLevelByMonth: getMRRRiskLevelByMonth(api),
  //Used in MRR 1'st chart (admins)
  getCountsByFacilities: getCountsByFacilities(api),
  //Used in dashboard and 2'nd chart (admins)
  getRiskLevelCountsByFacility: getRiskLevelCountsByFacility(api),
  //Used in 3'd chart (admins)
  getFacilitiesCountsByRequester: getFacilitiesCountsByRequester(api),
  getRelations: getRelations(api),
  //Used in 4'th chart (admins)
  getFacilitiesMedicalsByStatus: getFacilitiesMedicalsByStatus(api),
};

export default MedicalRRService;
