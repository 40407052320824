/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DashboardMode } from '@declarations/common/enums/DashboardMode';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

export interface SessionState {
  dashboardMode: DashboardMode;
}

export const SESSION = 'session';

const initialState: SessionState = localStorage.getItem(SESSION) 
  ? JSON.parse(localStorage.getItem(SESSION) || '')
  : {dashboardMode: DashboardMode.LTC};

export const sessionSlice = createSlice({
  name: SESSION,
  initialState,
  reducers: {
    setDashboardMode(state: SessionState, action: PayloadAction<DashboardMode>) {
      state.dashboardMode = action.payload;
      localStorage.setItem(SESSION, JSON.stringify(state));
    },
    switchDashboardMode(state: SessionState) {
      const newDashboardMode = state.dashboardMode === DashboardMode.LTC ? DashboardMode.Expert : DashboardMode.LTC;
      state.dashboardMode = newDashboardMode;
      localStorage.setItem(SESSION, JSON.stringify(state));
    },
  },
});

export const SessionActions = sessionSlice.actions;

const SessionSelector = (state: RootState): SessionState => state.session;

export const SessionSelectors = {
  dashboardMode: createSelector(SessionSelector, (session) => session.dashboardMode),
};

export default sessionSlice.reducer;
