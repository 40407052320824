import React, { useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, UserSelectors } from '@store/slices/user';
import AuthService from '@api/AuthService';
import ProfileList from './ProfileList';
import Transitions from './Transitions';
import routes from '../../constants/routes';
import useTheme from '../../themes/useTheme';
import { SettingsActions } from '@store/slices/settings';
import { clearAllStates } from '@components/DataGrid/utils/cache';
import { SessionActions, SessionSelectors } from '@store/slices/session';

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(UserSelectors.user);
  const hasTwoRoles = useSelector(UserSelectors.hasTwoRoles);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    dispatch(UserActions.logout());
    dispatch(SettingsActions.setFacility(null));
    dispatch(SettingsActions.setOrganization(null));
    dispatch(SettingsActions.setSelectedYear(''));

    clearAllStates();

    window.location.replace(AuthService.links.logout());
  };

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const onClickViewProfile = () => {
    navigate(routes.userProfile);
    setOpen(false);
  };

  const handleSwitchDashboard = () => {
    dispatch(SessionActions.switchDashboardMode());
    setOpen(false);
  }

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <AccountCircleOutlinedIcon />
          <Typography variant="body1">
            {user.firstname}
            {' '}
            {user.lastname}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <CardContent sx={{ py: 1, px: 2 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            {/* <AccountCircleOutlinedIcon /> */}
                            <Stack>
                              <Typography variant="h6">
                                {user.firstname}
                                {' '}
                                {user.lastname}

                              </Typography>

                              <Typography variant="body2" color="textSecondary">
                                {user.position}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        {/* <Grid item>
                          <IconButton size="large" onClick={handleLogout}>
                            <LogoutOutlinedIcon />
                          </IconButton>
                        </Grid> */}
                      </Grid>
                    </CardContent>

                    <Divider />

                    <ProfileList
                      userHasTwoRoles={hasTwoRoles}
                      dashboardMode={dashboardMode}
                      onClickViewProfile={onClickViewProfile}
                      handleLogout={handleLogout}
                      switchDashboard={handleSwitchDashboard}
                    />
                  </Box>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}

export default Profile;
