import React, { useEffect, useCallback, PropsWithChildren } from "react";
import { SESSION, SessionActions } from "@store/slices/session";
import { useDispatch } from "react-redux";


function LocalStorageProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch()
  const handleStorage = useCallback(() => {
    const session = JSON.parse(localStorage.getItem(SESSION) || '');
    if (!session?.dashboardMode) {
      return;
    }
    dispatch(SessionActions.setDashboardMode(session.dashboardMode));   
  }, [])

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  return <>{children}</>
}

export default LocalStorageProvider;