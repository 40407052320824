import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import pick from 'lodash/pick';
import { DataGrid, useGridModels } from '@components/DataGrid';
import useGridData from '@components/DataGrid/hooks/useGridData';
import LawsuitService from '@api/LawsuitService';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { UserSelectors } from '@store/slices/user';
import TableToolbar from '@components/TableToolbar';
import { Button } from '@mui/material';
import routes from '@constants/routes';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import getDifferent from '@utils/getDifferent';
import lawsuitColumns from './columns';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import useExpertUsers from '@hooks/useExpertUsers';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';


type LawsuitReportsGridProps = {
  lawsuitsReportGridKey: string;
  onLawsuitsRemoved?: (ids: number[]) => Promise<void>;
  onLawsuitPatched?: (newValues: Partial<Lawsuit>) => Promise<void>;
  gridFilterItem?: GridFilterItem;
};

function LawsuitReportsGrid(props: LawsuitReportsGridProps) {
  const userType = useSelector(UserSelectors.type);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);
  const {hasAccess: hasRemovalAccess} = useAccess({types: [UserType.SuperAdmin]});
  const gridModels = useGridModels();
  const fetchList = useCallback(LawsuitService.listWithRole(dashboardMode), [dashboardMode]);
  const fetchCSV = useCallback(LawsuitService.exportCSVWithRole(dashboardMode), [dashboardMode]);
  const {data, getData, loading} = useGridData({fetchMethod: fetchList, gridModels});
  const user = useSelector(UserSelectors.user);
  const {clinicalReviewers, legalReviewers, usersLoading, setPromiseArguments, renderConfirmDialog} = useExpertUsers(user.type);

  const onRemove = async () => {
    const ids = gridModels.rowSelectionModel as number[];
    await LawsuitService.remove(ids);
    gridModels.setPage(0);
    gridModels.setSelection([]);
    await getData();
    props?.onLawsuitsRemoved?.(ids);
  };

  const onInlineEdit = useCallback(async (newRow: Lawsuit, oldRow: Lawsuit) => {
    const different = getDifferent(oldRow, newRow);
    const newValues = pick(newRow, different);

    if (different[0] === 'clinicalReviewer' || different[0] === 'legalReviewer') {
      return new Promise<Lawsuit>((resolve, reject) => {
        const expertRole = different[0] === 'clinicalReviewer' ? ExpertRole.ClinicalReviewer : ExpertRole.LegalReviewer;
        setPromiseArguments({newRow, oldRow, reject, resolve, expertRole, reportType: ReportTypeEnum.Lawsuit});
      });
    }

    if (Object.keys(newValues).length) {
      await toast.promise(LawsuitService.patch(newRow.id, newValues), {
        pending: 'Updating...',
        success: 'Successfully updated',
        error: 'Something went wrong',
      });
      if (newValues.calculatedStatus !== undefined) {
        getData();
      }
      props?.onLawsuitPatched?.(newValues);
    }
    return newRow;
  }, [data]);

  const areColumnsEditable = useMemo(() => userType === UserType.SuperAdmin, [userType]);
  const columns = useMemo(() => lawsuitColumns(
      {userType, clinicalReviewers, legalReviewers, inlineEditEnabled: areColumnsEditable}),
    [userType, clinicalReviewers, legalReviewers],
  );

  const pinnedColumns: string[] | undefined = user.expertRole === ExpertRole.ClinicalReviewer
    ? ['clinicalReviewCompleted']
    : user.expertRole === ExpertRole.LegalReviewer ? ['legalReviewCompleted'] : undefined;

  return (
    <>
      {renderConfirmDialog()}

      <TableToolbar
        onRemove={onRemove}
        exportCSVFile={{
          fileName: () => `lawsuit-records-${new Date().toLocaleDateString('en-US')}.csv`,
          fetchMethod: fetchCSV,
          fetchParams: gridModels.getFetchListParams(),
        }}
        showRemove={gridModels.getSelectionCount() > 0}
        removalModalTitle="Are you sure you want to delete selected reports?"
        removalModalContent={`You selected ${gridModels.getSelectionCount()} report(s) to delete.`}
      >
        {(!user.expertRole || dashboardMode === DashboardMode.LTC) && <Button component={Link} variant="contained" to={routes.lawsuitForm}>
            New Lawsuit
        </Button>}
      </TableToolbar>

      <DataGrid
        showQuickFilter
        loading={loading}
        rows={data}
        columns={columns}
        checkboxSelection={hasRemovalAccess}
        processRowUpdate={onInlineEdit}
        stateName={props.lawsuitsReportGridKey}
        gridFilterItem={props.gridFilterItem}
        gridHeight={698}
        pinnedColumns={pinnedColumns}
        usersLoading={usersLoading}
        {...gridModels}
      />
    </>
  );
}

export default LawsuitReportsGrid;
