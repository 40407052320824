import { useSelector } from 'react-redux';
import FacilityIncidentReports from './FacilityIncidentReports';
import TotalIncidentReports from './TotalIncidentReports';
import { SettingsSelectors } from '@store/slices/settings';
import { UserSelectors } from '@store/slices/user';
import { UserType } from '@declarations/common/enums/UserType';
import IncidentReportsGrid from './components/IncidentReportsGrid';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

function IncidentReports() {
  const facility = useSelector(SettingsSelectors.selectedFacility);
  const user = useSelector(UserSelectors.user);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);
  if (user.expertRole && dashboardMode === DashboardMode.Expert) {
    return <IncidentReportsGrid incidentReportGridKey={'facility-incidents'} gridFilterItem={undefined}/>
  }
  return facility ? <FacilityIncidentReports/> : <TotalIncidentReports/>;
}

export default IncidentReports;
