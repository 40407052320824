import { ListWithPagination } from '@declarations/common/pagination';
import { Lawsuit } from '@declarations/models/reports/Lawsuit';
import { FetchListParams } from '@declarations/common/fetchListParams';
import getCountsByMonth from '@api/LawsuitService/getCountsByMonth';
import getCountsByStatus from '@api/LawsuitService/getCountsByStatus';
import getCountsByLawsuitStatus from '@api/LawsuitService/getCountsByLawsuitStatus';
import getFacilityTotals from '@api/LawsuitService/getFacilityTotals';
import initInstance from '../axios';
import getLawsuitCountsByFacility from '@api/LawsuitService/getLawsuitCountsByFacility';
import getTotalsByFacilities from '@api/LawsuitService/getTotalsByFacilities';
import getFacilitiesLawsuitsCountsByStatus from '@api/LawsuitService/getFacilitiesLawsuitsCountsByStatus';
import getFacilitiesLawsuitsCountsByLawsuitStatus from '@api/LawsuitService/getFacilitiesLawsuitsCountsByLawsuitStatus';
import getRelations from './getRelations';
import setExpertUsers from '@utils/setExpertUsers';
import setUploadedFiles from '@utils/setUploadedFiles';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';

const api = initInstance('/report/lawsuit');

const paths = {
  upsert: '/',
  getById: (id: number) => `/${id}`,
  list: '/list',
  listCSV: '/list/csv',
  patch: (id: number) => `/${id}`,
  remove: '/remove',
};

const LawsuitService = {
  upsert: async (lawsuit: Lawsuit | FormData) => api.post<Lawsuit>(paths.upsert, lawsuit),
  getById: async (id: number) => api.get(paths.getById(id)).then(setUploadedFiles),
  list: async (params: FetchListParams) =>
    api.post<ListWithPagination<Lawsuit>>(paths.list, params).then(res => setExpertUsers(res)),
  listWithRole: (role: DashboardMode) => async (params: FetchListParams) =>
    api
      .post<ListWithPagination<Lawsuit>>(paths.list, params, { params: { role_type: role } })
      .then(res => setExpertUsers(res)),
  patch: async (id: number, lawsuit: Partial<Lawsuit>) => api.patch(paths.patch(id), lawsuit),
  remove: async (ids: number[]) => api.post(paths.remove, ids),
  exportCSV: async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params),
  exportCSVWithRole: (role: DashboardMode) => async (params: Omit<FetchListParams, 'pagination'>) =>
    api.post<File>(paths.listCSV, params, { params: { role_type: role } }),
  countsByMonth: getCountsByMonth(api),
  countsByStatus: getCountsByStatus(api),
  countsByLawsuitStatus: getCountsByLawsuitStatus(api),
  getFacilityTotals: getFacilityTotals(api),
  getTotalsByFacilities: getTotalsByFacilities(api),
  //Used in the dashboard (admins)
  getLawsuitCountsByFacility: getLawsuitCountsByFacility(api),
  //Used in 1'st chart (Lawsuit admins)
  getFacilitiesLawsuitsCountsByStatus: getFacilitiesLawsuitsCountsByStatus(api),
  //Used in 2'nd chart (Lawsuit admins)
  getFacilitiesLawsuitsCountsByLawsuitStatus: getFacilitiesLawsuitsCountsByLawsuitStatus(api),
  getRelations: getRelations(api),
};

export default LawsuitService;
